<template>
  <table-view
    ref="tableView"
    url="/car/break/regulations/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :headers="headers"
    show-index
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query" inline>
      <el-input clearable v-model="dataForm.number" placeholder="自编号"></el-input>
      <el-input clearable v-model="dataForm.licensePlate" placeholder="车牌号码"></el-input>
      <!-- <el-input clearable v-model="dataForm.regulationsStatus" placeholder="违章状态"></el-input> -->
      <el-select v-model="dataForm.regulationsStatus" clearable filterable placeholder="状态">
        <el-option v-for="item in regulationsStatusList" :key="item.key" :label="item.key" :value="item.value"></el-option>
      </el-select>
      <el-form-item label="违章时间">
        <el-date-picker clearable value-format="timestamp" v-model="dataForm.startTime"></el-date-picker>
        <label>到</label>
        <el-date-picker clearable value-format="timestamp" v-model="dataForm.endTime"></el-date-picker>
      </el-form-item>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
    <template v-slot:appendButtons>
      <el-tooltip content="全部同步" placement="top">
        <el-button icon="el-icon-refresh" circle type="success" @click="refreshAll"></el-button>
      </el-tooltip>
    </template>
    <table-action-column slot="append" :width="60" v-if="isAuthed('car_break_refresh')">
      <template slot-scope="scope">
        <el-tooltip content="同步" placement="left">
          <el-button
            circle
            size="mini"
            type="success"
            icon="el-icon-refresh"
            @click="refresh(scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </table-action-column>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'
import TableActionColumn from '@/components/templates/table-action-column'

export default {
  name: 'car-violation',

  components: { TableView, TableActionColumn },

  data () {
    return {
      headers: [
        { id: 1, prop: 'number', label: '自编号', width: 150 },
        { id: 2, prop: 'licensePlate', label: '车牌号码', width: 120 },
        { id: 3, prop: 'carSize', label: '车辆型号', width: 100 },
        { id: 4,
          prop: 'regulationsStatus',
          label: '违章状态',
          width: 150,
          formatter: (row, column, cellValue) => {
            // 1 待完成 2 进行中 3 已完成
            switch (cellValue) {
              case 1:
                return '未处理未缴费'
              case 2:
                return '已处理未缴费'
              case 3:
                return '已处理未缴费'
            }
          }
        },
        { id: 5, prop: 'point', label: '扣分', width: 80 },
        { id: 6, prop: 'fine', label: '罚款', width: 80 },
        { id: 7, prop: 'behavior', label: '违章行为', width: 300 },
        { id: 9, prop: 'place', label: '违章地点', minWidth: 120 },
        { id: 10, prop: 'regulationsTime', label: '违章时间', width: 100 }
      ],
      dataForm: {
        number: '',
        licensePlate: '',
        regulationsStatus: '',
        startTime: '',
        endTime: ''
      },
      regulationsStatusList: [
        { key: '未处理未缴费', value: 1 },
        { key: '未处理已缴费', value: 2 },
        { key: '已处理已缴费', value: 3 }
      ]
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    },

    refreshAll () {
      this.$http({
        url: this.$http.adornUrl('/car/break/regulations/syncAll'),
        method: 'post'
      }).then((data) => {
        this.successCallback(data)
      })
    },

    refresh (row) {
      this.$http({
        url: this.$http.adornUrl('/car/break/regulations/syncById'),
        method: 'post',
        data: row.carId
      }).then((data) => {
        this.successCallback(data)
      })
    },

    successCallback (data) {
      if (data.code === 200) {
        this.query()
        this.$opts.success()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__content {
  .el-date-editor {
    display: inline-block;
    width: 140px;
  }
  label {
    margin: 10px;
  }
}
</style>
